import store from "../store/store";
import axios from "@/axios";
import { handleProductDetailRedirect, handleBeforeEnterPermissionRedirect } from "../utils/navigationsGuard";

export default [
  {
    path: "/doctor",
    redirect: "/doctor/notice-board",
    meta: {
      rule: "editor",
    },
    component: () => import("@/layouts/main/doctor/DoctorMain.vue"),
    children: [
      {
        path: "notice-board",
        name: "notice-board",
        component: () => import("@/views/basic/Dashboard.vue"),
        meta: {
          pageTitle: "Fresh Clinics | My Organisations",
          rule: "editor",
        },
      },
      {
        path: "events",
        name: "events",
        component: () => import("@/views/basic/Events.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Events",
          rule: "editor",
        },
      },
      {
        path: "supplier-training",
        name: "supplier-training",
        component: () => import("@/views/basic/SupplierTraining.vue"),
        meta: {
        pageTitle: "Fresh Clinics | Supplier Training",
        rule: "editor",
        },
      },
      {
        path: "referral",
        name: "referral",
        component: () => import("@/views/pages/referral/referralPage.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Refer a Friend",
          rule: "editor",
        },
      },
      {
        path: "resources",
        name: "resources",
        component: () => import("@/views/nurse/Resources.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "resources/search",
        name: "doctor-search-resources",
        component: () => import("@/views/nurse/ResourceSearch.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "resources/:topic",
        name: "doctor-topic-resources",
        component: () => import("@/views/nurse/ResourceTopic.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "resources/:topic/:brand",
        name: "doctor-partner-resources",
        component: () => import("@/views/nurse/ResourcePartner.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "setup-bank-detail",
        name: "DoctorBankDetail",
        component: () => import("@/views/doctor/DoctorBankDetail.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "doctor/dashboard" },
            { title: "Bank Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Bank Detail",
          rule: "editor",
        },
      },
      {
        path: "register",
        name: "DoctorRegister",
        component: () => import("@/views/doctor/DoctorRegister.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Doctor Register",
          rule: "editor",
        },
      },
      {
        path: "dashboard",
        name: "DoctorDashboard",
        component: () => import("@/views/doctor/DoctorDashboard.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Dashboard",
          rule: "editor",
        },
      },
      {
        path: "change-password",
        name: "DoctorChangePassword",
        component: () => import("@/views/pages/changePassword.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "doctor/dashboard" },
            { title: "Change Password", active: true },
          ],
          pageTitle: "Fresh Clinics | Change Password",
          rule: "editor",
        },
      },
      {
        path: "success",
        name: "doctor-change-password-success",
        component: () => import("@/views/pages/Success.vue"),
        meta: {
          rule: "editor",
        },
      },
      {
        path: "clinics",
        name: "DoctorClinic",
        component: () => import("@/views/org-owner/clinics/list.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "doctor/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Clinics | List",
          rule: "editor",
        },
      },
      {
        path: "clinic/:clinicId/edit",
        name: "DoctorEditClinic",
        component: () =>
          import("@/views/org-owner/clinics/editClinic.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "doctor/dashboard" },
            { title: "Clinics", url: "/doctor/clinics" },
            { title: "Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Clinics | Detail",
          rule: "editor",
        },
        beforeEnter: async (to, from, next) => {
          handleBeforeEnterPermissionRedirect(to, from, next, "Clinic", "notice-board");
        }
      },
      {
        path: "clinics/:clinicId/respond-clinic-join-request/:requestId",
        name: "DoctorRespondClinicJoinRequest",
        component: () => import("@/views/doctor/DoctorClinicJoinRequest.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "doctor/dashboard" },
            { title: "Clinics", url: "/doctor/clinics" },
            { title: "Clinic Join Request", active: true },
          ],
          pageTitle: "Fresh Clinics | Clinics | Clinic Join Request",
          rule: "editor",
        },
      },
      {
        path: "profile",
        name: "DoctorProfileDetail",
        component: () => import("@/views/doctor/DoctorProfileDetail.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "doctor/dashboard" },
            { title: "Profile", active: true },
          ],
          pageTitle: "Fresh Clinics | Profile",
          rule: "editor",
        },
      },
      {
        path: "patients",
        name: "DoctorPatientsList",
        component: () =>
          import("@/views/doctor/patients/DoctorPatientList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "doctor/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Patients | List",
          rule: "editor",
        },
      },
      {
        path: "patients/:patientId/details",
        name: "DoctorPatientDetail",
        component: () =>
          import("@/views/doctor/patients/DoctorPatientDetail.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "doctor/dashboard" },
            { title: "Patients", url: "/doctor/patients" },
            { title: "Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Patients | Detail",
          rule: "editor",
        },
      },
      {
        path: "patients/:patientId/treatment/:checkinId",
        name: "DoctorPatientTreatmentDetail",
        component: () =>
          import("@/views/doctor/patients/PatientTreatmentDetails.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "doctor/dashboard" },
            { title: "Patients", url: "/doctor/patients" },
            { title: "Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Patients | Treatment Detail",
          rule: "editor",
        },
      },
      {
        path: "notifications",
        name: "doctorNotification",
        component: () =>
          import("@/views/doctor/notifications/notifications.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "nurse/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Notifications | List",
          rule: "editor",
        },
      },
      {
        path: "treatment-history",
        name: "DoctorTreatmentHistory",
        component: () =>
          import("@/views/doctor/history/TreatmentList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "doctor/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Treatment History | List",
          rule: "editor",
        },
      },
      /** Doctor Shop routes */
      {
        path: "shop",
        name: "DoctorOrderCenter",
        component: () =>
          import("@/views/org-owner/ecommerce/OrderCenter.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/shop" },
            { title: ['AU'].includes(process.env.VUE_APP_REGION) ? "Products" : "Shop", active: true },
          ],
          pageTitle: "Fresh Clinics | " + (['AU'].includes(process.env.VUE_APP_REGION) ? "Products" : "Shop"),
          rule: "editor",
          permission: 'Shop'
        },
      },
      {
        path: "shop/category/:slug",
        name: "DoctorOrderCenterCategoryPage",
        component: () =>
          import("@/views/org-owner/ecommerce/Category.vue"),
        meta: {
          pageTitle: "Fresh Clinics | " + (['AU'].includes(process.env.VUE_APP_REGION) ? "Products" : "Shop"),
          rule: "editor",
        },
      },
      {
        path: "shop/promotions",
        name: "DoctorOrderCenterPromotionsPage",
        component: () =>
          import("@/views/org-owner/ecommerce/ShopPromotions.vue"),
        meta: {
          pageTitle: "Fresh Clinics | " + (['AU'].includes(process.env.VUE_APP_REGION) ? "Products" : "Shop"),
          rule: "editor",
        },
      },
      {
        path: "shop/fresh-life",
        name: "DoctorOrderCenterFreshLifePage",
        component: () =>
          import("@/views/org-owner/ecommerce/FreshlifePromotion.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Shop | Fresh Life Promos",
          rule: "editor",
        },
      },
      {
        path: "shop/search",
        name: "DoctorOrderCenterSearch",
        component: () =>
          import("@/views/org-owner/ecommerce/SearchResult.vue"),
        meta: {
          pageTitle: "Fresh Clinics | " + (['AU'].includes(process.env.VUE_APP_REGION) ? "Products" : "Shop"),
          rule: "editor",
        },
      },
      {
        path: "shop/brands/:slug",
        name: "DoctorOrderCenterBrandPage",
        component: () =>
          import("@/views/org-owner/ecommerce/Brand.vue"),
        meta: {
          pageTitle: "Fresh Clinics | " + (['AU'].includes(process.env.VUE_APP_REGION) ? "Products" : "Shop"),
          rule: "editor",
        },
      },
      {
        path: "orders",
        name: "DoctorStaffOrderList",
        component: () =>
          import("@/views/org-owner/ecommerce/order/OrderList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/shop" },
            { title: "Orders", active: true },
          ],
          pageTitle: "Fresh Clinics | Orders",
          rule: "editor",
          permission: 'Shop'
        },
      },
      {
        path: "product/:id",
        name: "DoctorProductDetail",
        component: () =>
          import("@/views/org-owner/ecommerce/ProductDetail.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/shop" },
            { title: "Products", url: "/doctor/shop" },
            { title: "Product Details", active: true },
          ],
          pageTitle: "Fresh Clinics | Product Detail",
          rule: "editor",
          permission: 'Shop'
        },
        beforeEnter: handleProductDetailRedirect,
      },
      {
        path: "checkout",
        name: "DoctorProductCheckout",
        component: () =>
          import("@/views/org-owner/ecommerce/ClinicCheckout.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/shop" },
            { title: "Products", url: "/doctor/shop" },
            { title: "Product Checkout", active: true },
          ],
          pageTitle: "Fresh Clinics | Product Checkout",
          rule: "editor",
          permission: 'Shop'
        },
      },
      {
        path: "order-complete",
        name: "DoctorOrderComplete",
        component: () =>
          import("@/views/org-owner/ecommerce/ClinicOrderComplete.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/shop" },
            { title: "Products", url: "/doctor/shop" },
            { title: "Order Complete", active: true },
          ],
          pageTitle: "Fresh Clinics | Order Complete",
          rule: "editor",
          permission: 'Shop'
        },
      },
      {
        path: "orders/:orderId",
        name: "DoctorOrderDetail",
        component: () =>
          import("@/views/org-owner/ecommerce/order/OrderDetail.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/shop" },
            { title: "Orders", url: "/doctor/orders" },
            { title: "Order Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Order Detail",
          rule: "editor",
          permission: 'Shop'
        },
      },
      {
        path: "invoices",
        name: "Doctorinvoices",
        component: () => import("@/views/organization/invoices/List.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/invoices" },
            { title: "Invoices", active: true },
          ],
          pageTitle: "Fresh Clinics | Invoices",
          rule: "editor",
        },
      },
      {
        path: "staff",
        name: "Doctorstaff",
        component: () => import("@/views/organization/users/index.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/staff" },
            { title: "Staff", active: true },
          ],
          pageTitle: "Fresh Clinics | Staff",
          rule: "editor",
        },
      },
      {
        path: "clinics/:clinicId/edit",
        name: "OrgOwnerClinicEdit",
        component: () => import("@/views/admin/clinics/clinicsEdit.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/clinics" },
            { title: "Clinics", url: "/doctor/clinics" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Clinics | Edit",
          rule: "editor",
        },
      },
      {
        path: "basic-user/:basicUserId",
        name: "DoctorBasicEditRoute",
        component: () =>
          import("@/views/org-owner/basic-user/BasicDetails.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/clinics" },
            { title: "Basic" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Basic | Edit",
          rule: "editor",
        },
        beforeEnter: async (to, from, next) => {
          handleBeforeEnterPermissionRedirect(to, from, next, "Staff", "notice-board");
        }
      },
      {
        path: "nurses/:nurseId",
        name: "DoctorNurseDetails",
        component: () => import("@/views/org-owner/nurses/NurseDetails.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/clinics" },
            { title: "Nurses", url: "/doctor/nurses" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Nurses | Edit",
          rule: "editor",
        },
        beforeEnter: async (to, from, next) => {
          handleBeforeEnterPermissionRedirect(to, from, next, "Staff", "notice-board");
        }
      },
      {
        path: "doctors/:doctorId/edit",
        name: "DoctorDoctorEdit",
        component: () => import("@/views/org-owner/doctors/doctorEdit.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/clinics" },
            { title: "Doctors", url: "/doctor/doctors" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Doctors | Edit",
          rule: "editor",
        },
        beforeEnter: async (to, from, next) => {
          handleBeforeEnterPermissionRedirect(to, from, next, "Staff", "notice-board");
        }
      },
      {
        path: "doctor-clinics/:franchiseId",
        name: "DoctorDoctorClinicsEdit",
        component: () =>
          import("@/views/org-owner/doctor-clinics/viewFranchise.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Doctor Clinics", url: "/org-owner/doctor-clinics" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Doctor Clinics | Edit",
          rule: "editor",
        },
      },
      {
        path: "derma-specialist/:franchiseId",
        name: "DoctorDermaSpecialistEdit",
        component: () =>
          import("@/views/org-owner/derma-specialist/viewFranchise.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/clinics" },
            {
              title: "Derma Specialist",
              url: "/doctor/derma-specialist",
            },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Derma Specialist | Edit",
          rule: "editor",
        },
      },
      {
        path: "settings/treatment-areas",
        name: "DoctorTreatmentAreas",
        component: () =>
          import("@/views/franchise/settings/TreatmentArea.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Treatment Areas",
          rule: "editor",
        },
      },
      {
        path: "settings/products-list",
        name: "DoctorProductsList",
        component: () =>
          import("@/views/franchise/settings/ProductsList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Treatment Areas",
          rule: "editor",
        },
      },
      /** Custom Product ROUTE START */
      {
        path: "settings/custom-products-list",
        name: "DoctorCustomProductsList",
        component: () =>
          import("@/views/franchise/settings/CustomProductsList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Custom Products",
          rule: "editor",
          permission: "CustomProduct",
        },
      },
      {
        path: "settings/custom-products-list/create",
        name: "DoctorCustomProductsListCreate",
        component: () =>
          import("@/views/components/org-owner/custom-products/AddCustomProduct.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Custom Products | Create",
          rule: "editor",
          permission: "CustomProduct",
        },
      },
      {
        path: "settings/custom-products-list/:productId/edit",
        name: "DoctorCustomProductsListDetails",
        component: () =>
          import("@/views/components/org-owner/custom-products/EditCustomProduct.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Custom Products | Edit",
          rule: "editor",
          permission: "CustomProduct",
        },
      },
      /** Custom Product ROUTE END */
      {
        path: "compliance",
        name: "DoctorCompliance",
        component: () =>
          import("@/views/org-owner/compliance/Compliance.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/review",
        name: "DoctorComplianceReview",
        component: () => import("@/views/org-owner/compliance/Review.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/clinics" },
            { title: "Compliance", url: "/doctor/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/step/image/upload",
        name: "DoctorComplianceStepImageUpload",
        component: () =>
          import("@/views/org-owner/compliance/complianceUpload.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/clinics" },
            { title: "Compliance", url: "/doctor/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/upload",
        name: "DoctorComplianceStepUpload",
        component: () =>
          import("@/views/org-owner/compliance/complianceVideoUpload.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/doctor/clinics" },
            { title: "Compliance", url: "/doctor/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "drugbook/setup",
        name: "DoctorDrugbook",
        component: () =>
          import("@/views/org-owner/drug-book/SetUpDrugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook",
          rule: "editor",
          permission:'Drug'
        },
      },
      {
        path: "drugbook/setup/clinic",
        name: "DoctorDrugbookTransfer",
        component: () =>
          import("@/views/org-owner/drug-book/TransferClinicDrugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook | Set up",
          rule: "editor",
          permission:'Drug'
        },
      },
      {
        path: "drugbook/entries",
        name: "DoctorDrugbookEntries",
        component: () =>
          import("@/views/org-owner/drug-book/drugbookEntries.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook | Outstanding",
          rule: "editor",
          permission:'Drug'
        },
      },
      {
        path: "drugbook",
        name: "DoctorDrugbookLanding",
        component: () =>
          import("@/views/org-owner/drug-book/Drugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook",
          rule: "editor",
          permission: "Drug"
        },
      },
      {
        path: "inventory",
        name: "DoctorInventory",
        component: () => import("@/views/org-owner/inventory/Inventory.vue"),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/doctor/clinics"},
            {title: "Inventory", active: true }
          ],
          pageTitle: "Fresh Clinics | Insight - Inventory",
          rule: "editor",
        },
      },
      /** CONSENT FORM ROUTE STARTED */
      {
        path: "consent-forms",
        name: "DoctorConsentForms",
        component: () => import("@/views/general-component/consent-forms/ConsentForms.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
          permission: "CustomConsentForm",
        },
      },
      {
        path: "consent-forms/:consentId/details",
        name: "DoctorConsentFormsDetails",
        component: () => import("@/views/general-component/consent-forms/ConsentFormsName.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
          permission: "CustomConsentForm",
        },
      },
      {
        path: "consent-forms/create",
        name: "DoctorConsentFormsCreate",
        component: () => import("@/views/general-component/consent-forms/ConsentFormsName.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
          permission: "CustomConsentForm",
        },
      },
      /** CONSENT FORM ROUTE ENDED */
      /** AFTERCARE ROUTE STARTED */
      {
        path: "aftercare",
        name: "DoctorAftercare",
        component: () => import("@/views/aftercare/AftercareDocuments.vue"),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/doctor/aftercare"}
          ],
          pageTitle: "Fresh Clinics | Aftercare",
          rule: "editor",
          permission: "CustomAftercareDocument",
        },
      },
      {
        path: "aftercare/create",
        name: "DoctorAftercareCreate",
        component: () => import("@/views/doctor/aftercare/CreateAftercare.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Create Aftercare",
          rule: "editor",
          permission: "CustomAftercareDocument",
        },
      },
      {
        path: "aftercare/:id/details",
        name: "DoctorAftercareDetails",
        component: () => import("@/views/doctor/aftercare/CreateAftercare.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Aftercare Details",
          rule: "editor",
          permission: "CustomAftercareDocument",
        },
      },
      /** AFTERCARE ROUTE ENDED */
    ],
  },
]
