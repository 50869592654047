/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

import moduleEmail from "./email/moduleEmail.js";
import moduleAuth from "./auth/moduleAuth.js";
import moduleOrganization from "./organization/moduleOrganization.js";
import moduleFranchise from "./franchise/moduleFranchise.js";
import moduleECommerce from "./eCommerce/moduleECommerce.js";
import moduleClinic from "./clinic/moduleClinic.js";
import moduleDoctorClinic from "./doctor-clinic/moduleClinic.js";
import moduleDoctor from "./doctor/moduleDoctor.js";
import moduleNurse from "./nurse/moduleNurse.js";
import moduleBasicUser from "./basic-user/moduleBasicUser.js";
import modulePatient from "./patient/modulePatient.js";
import modulePage from "./page/modulePage.js";
import moduleAdmin from "./admin/moduleAdmin";
import moduleAppUser from "./appUser/moduleAppUser";
import general from "./general/moduleGeneral";
import moduleQuestionnaire from "./questionnaire/moduleQuestionnaire";
import moduleProduct from "./product/moduleProduct";
import moduleRolesAndPermissions from "./rolesAndPermissions/moduleRolesAndPermissions";
import moduleLog from "./logs/moduleLog";
import moduleCategory from "./category/moduleCategory";
import moduleStoreProducts from "./storeProducts/moduleStoreProducts";
import moduleSuppliers from "./webstoreSuppliers/moduleWebstoreSupplier";
import moduleStoreOrder from "./webStoreOrders/moduleWebstoreOrder";
import onboard from "./onboarding/moduleOnboard";
import moduleDiscount from "./discount/moduleDiscount";
import moduleVoucher from "./voucher/moduleVoucher.js";
import moduleTreatmentTypes from "./treatmentTypes/moduleTreatmentTypes";
import moduleHubspot from "./hubspot/moduleHubspot";
import moduleBasic from "./basic/moduleBasic";
import moduleOrgOwner from "./orgOwner/moduleOrgOwner";
import moduleCommunityMembers from "./community-members/moduleCommunityMembers";
import moduleBasicUsers from "./basic-user/moduleBasicUser";
import moduleMonthlyCompliance from "./monthlyCompliance/moduleMonthlyCompliance";
import moduleDrugbook from "./drugbook/moduleDrugbook";
import moduleAnalytics from "./analytics/moduleAnalytics";
import moduleStoreFeedbackSurvey from "./storeFeedbackSurvey/moduleStoreFeedbackSurvey";
import moduleConsentForms from "./consent-forms/moduleConsentForms";
import moduleBrand from "./brand/moduleBrand";
import moduleStoreAlertNotification from "./storeAlertNotification/moduleStoreAlertNotification";
import moduleAI from "./ai/moduleAI";
import moduleResourceHub from "./resource-hub/moduleResourceHub";
import moduleAccountLevels from "./account-level/moduleAccountLevel";
import moduleReportingCategory from "./reporting-category/moduleReportingCategory.js";
import moduleAppointment from "./appointment/moduleAppointment";
import moduleAftercare from "./aftercare/moduleAftercare";
import moduleTierPackage from "./tier-package/moduleTierPackage";


export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    appointment: moduleAppointment,
    organization: moduleOrganization,
    franchise: moduleFranchise,
    communityMemebers: moduleCommunityMembers,
    clinic: moduleClinic,
    doctorClinic: moduleDoctorClinic,
    doctor: moduleDoctor,
    patient: modulePatient,
    eCommerce: moduleECommerce,
    page: modulePage,
    email: moduleEmail,
    admin: moduleAdmin,
    nurse: moduleNurse,
    basicUser: moduleBasicUser,
    appUser: moduleAppUser,
    general: general,
    questionnaire: moduleQuestionnaire,
    product: moduleProduct,
    rolesAndPermissions: moduleRolesAndPermissions,
    log: moduleLog,
    ecommerce: moduleECommerce,
    storeProducts: moduleStoreProducts,
    category: moduleCategory,
    discount: moduleDiscount,
    voucher: moduleVoucher,
    supplier: moduleSuppliers,
    storeOrder: moduleStoreOrder,
    onboard: onboard,
    treatmentTypes: moduleTreatmentTypes,
    hubspot: moduleHubspot,
    basic: moduleBasic,
    orgOwner: moduleOrgOwner,
    monthlyCompliance: moduleMonthlyCompliance,
    drugbook: moduleDrugbook,
    analytics: moduleAnalytics,
    storeFeedbackSurvey: moduleStoreFeedbackSurvey,
    consentForms: moduleConsentForms,
    brand: moduleBrand,
    storeAlertNotification: moduleStoreAlertNotification,
    ai: moduleAI,
    resourceHub: moduleResourceHub,
    accountLevels: moduleAccountLevels,
    reportingCategory: moduleReportingCategory,
    aftercare: moduleAftercare,
    tierPackage: moduleTierPackage
  },
  strict: process.env.NODE_ENV !== "production"
});
